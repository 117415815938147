import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

export const Client = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.GATSBY_GRAPHQL_URL}`,
    fetch,
  }),
  cache: new InMemoryCache(),
});
