module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/locales","languages":["en","fr"],"defaultLanguage":"en","redirect":false,"redirectDefaultLanguageToRoot":true,"ignoredPaths":[],"fallbackLanguage":"en"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1a4a9709cd4db72b64de7a980b6f1580"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
