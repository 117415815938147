import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { APPLICATION_INSIGHTS_ROLE_NAME } from './src/constants';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './src/scss/custom.scss';
import './src/fonts/fonts.css';

import wrapRootElement from './wrapRootElement';

/* Application Insights Initialization */
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.GATSBY_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true,
    correlationHeaderExcludedDomains: [],
    disableExceptionTracking: false,
    disableFetchTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
  },
});

const telemetryInitializer = (envelope) => {
  // eslint-disable-next-line no-param-reassign
  envelope.tags['ai.cloud.role'] = APPLICATION_INSIGHTS_ROLE_NAME;
  // eslint-disable-next-line no-param-reassign
  envelope.tags['ai.cloud.roleInstance'] = APPLICATION_INSIGHTS_ROLE_NAME;
};

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(telemetryInitializer);
appInsights.trackPageView();

export { wrapRootElement, appInsights };
