export const CONTAINER_NAMES = {
  ESG_ENVIRONMENT_COMPONMENT: 'ESG_ENVIRONMENT_COMPONMENT',
  ESG_GOVERNANCE_COMPONMENT: 'ESG_GOVERNANCE_COMPONMENT',
  CUSTOMER_RESPONSIBILITY: 'CUSTOMER_RESPONSIBILITY',
  CONTENT_AND_IMAGE_HORIZONTAL_STATS: 'CONTENT_AND_IMAGE_HORIZONTAL_STATS',
  HERO_BANNER: 'HERO_BANNER',
  CONTENT_IMAGE_VARIATION_BANNER: 'CONTENT_IMAGE_VARIATION_BANNER',
  OUR_AWARDS: 'OUR_AWARDS',
  OUR_BRANDS: 'OUR_BRANDS',
  OUR_HISTORY: 'OUR_HISTORY',
  IN_THE_COMMUNITY_COMPONENT: 'IN_THE_COMMUNITY_COMPONENT',
  CONTENT_IMAGE_STATS: 'CONTENT_IMAGE_STATS',
  WHO_WE_ARE: 'WHO_WE_ARE',
  PRESS_RELEASES: 'PRESS_RELEASES',
  IMAGE_AND_CONTENT: 'IMAGE_AND_CONTENT',
  WOVEN_IMAGE_AND_CONTENT: 'WOVEN_IMAGE_AND_CONTENT',
  CORPORATE_OVERVIEW: 'CORPORATE_OVERVIEW',
  INVESTOR_HERO_BANNER: 'INVESTOR_HERO_BANNER',
  CONTENT_AND_VIDEO: 'CONTENT_AND_VIDEO',
  CONTENT_AND_VIDEO_VARIATION_TYPE_ONE: 'CONTENT_AND_VIDEO_VARIATION_TYPE_ONE',
  CONTENT_AND_VIDEO_VARIATION_TYPE_TWO: 'CONTENT_AND_VIDEO_VARIATION_TYPE_TWO',
  ABOUTUS_STATISTICS: 'ABOUTUS_STATISTICS',
  NOT_FOUND_PAGE: 'NOT_FOUND_PAGE',
  OUR_BRANDS_EFS: 'OUR_BRANDS_EFS',
  ABOUTUS_WHO_WE_ARE: 'ABOUTUS_WHO_WE_ARE',
  WHO_WE_HELP: 'WHO_WE_HELP',
  OUR_BRANDS_EH: 'OUR_BRANDS_EH',
  OUR_BRANDS_LC: 'OUR_BRANDS_LC',
  OUR_BRANDS_LENDCARE_SECTION: 'OUR_BRANDS_LENDCARE_SECTION',
  WHY_INVEST_EASYFINANCIAL: 'WHY_INVEST_EASYFINANCIAL',
  WHY_INVEST_EASYHOME: 'WHY_INVEST_EASYHOME',
  WHY_INVEST_CORPORATE_HIGHLIGHTS: 'WHY_INVEST_CORPORATE_HIGHLIGHTS',
  WHY_INVEST_COMPANY_HISTORY: 'WHY_INVEST_COMPANY_HISTORY',
  CAREER_PAGE_CONTENT_AND_VIDEO: 'CAREER_PAGE_CONTENT_AND_VIDEO',
  CAREER_PAGE_OUR_TEAMS: 'CAREER_PAGE_OUR_TEAMS',
  CAREER_PAGE_MISSION_LOGOS: 'CAREER_PAGE_MISSION_LOGOS',
  STOCK_QUOTE: 'STOCK_QUOTE',
  HISTORICAL_STOCK_QUOTE: 'HISTORICAL_STOCK_QUOTE',
  LEADERSHIP_TEAM_HERO_BANNER: 'LEADERSHIP_TEAM_HERO_BANNER',
  CAREER_PAGE_OUR_CORE_PILLARS: 'CAREER_PAGE_OUR_CORE_PILLARS',
  CAREER_PAGE_OUR_AWARD_WINNING: 'CAREER_PAGE_OUR_AWARD_WINNING',
  WHY_INVEST_ANNUAL_REVENUE_GROWTH: 'WHY_INVEST_ANNUAL_REVENUE_GROWTH',
  CAREER_PAGE_OPPORTUNITIES: 'CAREER_PAGE_OPPORTUNITIES',
  STOCK_INFO_TABS: 'STOCK_INFO_TABS',
  STOCK_CHART: 'STOCK_CHART',
  ANALYST_COVERAGE: 'ANALYST_COVERAGE',
  LEADERSHIP_EXECUTIVE_MANAGEMENT_TEAM: 'LEADERSHIP_EXECUTIVE_MANAGEMENT_TEAM',
  INVESTMENT_CALCULATOR: 'INVESTMENT_CALCULATOR',
  FINANCE_FILLING_HIGHLIGHTS: 'FINANCE_FILLING_HIGHLIGHTS',
  BOARD_OF_DIRECTORS: 'BOARD_OF_DIRECTORS',
  NEWS_AND_EVENTS: 'NEWS_AND_EVENTS',
  PRESSRELEASE_NEWS_EVENTS: 'PRESSRELEASE_NEWS_EVENTS',
  NEWS_AND_EVENTS_PRESS_RELEASES: 'NEWS_AND_EVENTS_PRESS_RELEASES',
  PAST_FINANCIAL_REPORTS: 'PAST_FINANCIAL_REPORTS',
  NEWS_EVENT_CALENDAR: 'NEWS_EVENT_CALENDAR',
  INVESTOR_CONTACT: 'INVESTOR_CONTACT',
  COMMUNITY_TESTIMONIAL: 'COMMUNITY_TESTIMONIAL',
  COMMUNITY_CONTENT_AND_VIDEO_VARIATION:
    'COMMUNITY_CONTENT_AND_VIDEO_VARIATION',
  IN_THE_COMMUNITY_SECTION: 'IN_THE_COMMUNITY_SECTION',
  IN_THE_COMMUNITY_HABITAT_HUMANITY: 'IN_THE_COMMUNITY_HABITAT_HUMANITY',
  IN_THE_COMMUNITY_SUPPORTING: 'IN_THE_COMMUNITY_SUPPORTING',
  NEWS_AND_EVENTS_PRESS_RELEASE: 'NEWS_AND_EVENTS_PRESS_RELEASE',
  COMMITMENT_TO_DIVERSITY: 'COMMITMENT_TO_DIVERSITY',
  CAREERS_WHAT_OUR_EMPLOYEES_ARE_SAYING:
    'CAREERS_WHAT_OUR_EMPLOYEES_ARE_SAYING',
  CAREERS_PAGE_HOW_WE_HELP_CANADIANS_SECTION:
    'CAREERS_PAGE_HOW_WE_HELP_CANADIANS_SECTION',
  COMMITMENT_TO_DIVERSITY: 'COMMITMENT_TO_DIVERSITY',
  NEWS_EVENTS_TEXT_CONTAINER: 'NEWS_EVENTS_TEXT_CONTAINER',
  HISTORY_TIMELINE: 'HISTORY_TIMELINE',
  NEWS_AND_EVENTS_PAST_EVENTS: 'NEWS_AND_EVENTS_PAST_EVENTS',
  FINANCE_FILLING_IMAGE_AND_CONTENT: 'FINANCE_FILLING_IMAGE_AND_CONTENT',
  CORPORATE_DOCUMENTS: 'CORPORATE_DOCUMENTS',
  UPCOMING_LATEST_EVENT: 'UPCOMING_LATEST_EVENT',
  UNSUBSCRIBE_FORM: 'UNSUBSCRIBE_FORM',
  SOCIAL_PEOPLE_AND_CULTURE: 'SOCIAL_PEOPLE_AND_CULTURE',
  OUR_COMMUNITIES: 'OUR_COMMUNITIES',
  EMAIL_ALERTS: 'EMAIL_ALERTS',
  PRIVACY_COMMITMENT: 'PRIVACY_COMMITMENT',
  PRIVACY_POLICY_TAB_CONTENT: 'PRIVACY_POLICY_TAB_CONTENT',
};
export const ADDITIONAL_PROPERTY = {
  CONTENT_ALIGNMENT: 'contentAlignment',
  BACKGROUND_COLOUR: 'backgroundColour',
  WIDTH: 'width',
};
export const APPLICATION_INSIGHTS_ROLE_NAME = 'goeasy-fe';
export const IFRAME_SOURCE_ORIGIN = 'https://goeasy.investorroom.com';
