/**
 * Global State Store for the application
 */
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import types from './types';

const initialState = {
  emailUsModal: false,
  emailusModalType: '',
  isStockTickerLoaded: false,
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((curState, action) => {
    switch (action.type) {
      case types.EMAIL_US_MODAL:
        return {
          ...curState,
          emailUsModal: action.payload.modal,
          emailusModalType: action.payload.type,
        };
      case types.STOCK_TICKER:
        return {
          ...curState,
          isStockTickerLoaded: action.payload.isLoaded,
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.object,
};

export { store, StateProvider };
