import React from 'react';
import { ApolloProvider } from '@apollo/client/react';
import { Client } from './src/apollo';

import { StateProvider } from './src/store/store';
const wrapPageElement = ({ element }) => {
  return (
    <ApolloProvider client={Client}>
      <StateProvider>{element}</StateProvider>
    </ApolloProvider>
  );
};

export default wrapPageElement;
